<template>
    <div class="h-full pt-10 bg-white flex flex-col">
        <div class="py-3">
            <h3 class="text-lg text-center text-gray-800">Payments</h3>
        </div>
        <div class="flex h-full items-center justify-center relative -top-12">
            <form class="w-64 px-3" @submit.prevent="send">
                <div class="mb-6">
                    <input
                        ref="providerInput"
                        class="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                        type="text"
                        required
                        placeholder="Provider"
                        v-model.trim="provider">
                </div>
                <div class="mb-6">
                    <input
                        class="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                        type="text"
                        required
                        placeholder="Client Account"
                        v-model.trim="clientAccount">
                </div>
                <div class="mb-6">
                    <input
                        class="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                        type="text"
                        required
                        placeholder="KES 100"
                        v-model.trim="amount">
                </div>
                <div
                    class="py-2 px-4 bg-red-200 -mt-3 mb-6 text-sm text-red-900 rounded"
                    v-if="validationError || sendPaymentError">
                    {{ validationError ? validationError : sendPaymentError }}
                </div>
                <button
                    type="submit"
                    :class="{ 'cursor-wait': sending === true }"
                    class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                    {{ sending ? 'Sending...' : 'Send Payment' }}
                </button>
            </form>
        </div>
        <div class="h-10 flex flex-row justify-center items-center border-t border-gray-300 mt-auto">
            <span
                @click="goHome"
                class="cursor-pointer text-gray-600 hover:text-gray-700 transition duration-150 ease-in-out">
                <homeSvg class="h-5 fill-current pointer-events-none" />
            </span>
        </div>
    </div>
</template>

<script>
import {
    mapState,
    mapActions,
} from 'vuex';

export default {
    name: 'Payments',

    props: {
        goHome: {
            type: Function,
            default: () => () => {},
        },
    },

    data() {
        return {
            provider: '',
            clientAccount: '',
            amount: '',
            validationError: '',
            sending: false,
        };
    },

    mounted() {
        this.$refs.providerInput.focus();
    },

    computed: {
        ...mapState('simulator/payments', [
            'sendPaymentError',
        ]),
    },

    methods: {
        ...mapActions('simulator/payments', [
            'sendPayment',
        ]),

        async send() {
            this.validationError = '';
            let validateError;

            if (this.provider.length === 0) {
                validateError = 'Provider is required';
            }

            if (this.clientAccount.length === 0) {
                validateError = 'Client Account is required';
            }

            if (this.amount.length === 0) {
                validateError = 'Amount is required';
            }

            const amountParts = this.amount.split(' ');
            const currency = amountParts[0];
            const amount = amountParts[1];

            if (!/^[a-z]{3}$/i.test(currency) || !/^\d+$/.test(amount)) {
                validateError = 'Invalid Amount';
            }

            if (validateError) {
                this.validationError = validateError;
                return;
            }

            const payment = {
                currencyCode: amountParts[0],
                amount: parseInt(amountParts[1], 10),
                paybill: this.provider,
                account: this.clientAccount,
            };

            this.sending = true;
            await this.sendPayment(payment);
            this.sending = false;

            if (!this.sendPaymentError) {
                this.provider = '';
                this.clientAccount = '';
                this.amount = '';
            }
        },
    },

    components: {
        homeSvg: () => import('@/assets/img/home.svg'),
    },
};
</script>
